<template>
  <div class="page">
    <div class="banner">
      <img src="../../static/community/banner.png" class="banner-img" />
    </div>
    <div class="course">
      <div class="tab-page">
        <div class="item" v-show="current == 0" @click="to">
          <img style="width: 360px; height: auto" src="../../static/news/news_img1.png" />
        </div>
        <div class="item" v-show="current != 0" @click="to">
          <div class="item-num">10</div>
          <div class="item-time">2021-06</div>
          <div class="item-title">乘风起航, 2021美蜂名医·破局之战</div>
          <div class="item-desc">
            导语：美蜂入局医美千亿市场流量之争：全国首个整形
            医师与求美者直接沟通问诊的APP能否突出重围。
          </div>
          <div class="item-arrow">
            <b-icon icon="arrow-right"></b-icon>
          </div>
        </div>
        <div class="item" v-show="current == 1" @click="to">
          <img style="width: 360px; height: auto" src="../../static/news/news_img2.png" />
        </div>
        <div class="item" v-show="current != 1" @click="to">
          <div class="item-num">24</div>
          <div class="item-time">2021-08</div>
          <div class="item-title">国内轻医美市场空白，美蜂注入全新流量 洼地</div>
          <div class="item-desc">
            近年来，随着我国医美行业快速发展，其中非手术类医美即
            轻医美，开始显露头角。相比动辄动刀动骨的重医美级别项
            目，例如光子嫩肤、果酸焕肤…
          </div>
          <div class="item-arrow">
            <b-icon icon="arrow-right"></b-icon>
          </div>
        </div>
        <div class="item" v-show="current == 2" @click="to">
          <img style="width: 360px; height: auto" src="../../static/news/news_img3.png" />
        </div>
        <div class="item" v-show="current != 2" @click="to">
          <div class="item-num">12</div>
          <div class="item-time">2021-10</div>
          <div class="item-title">
            “颜值经济”刺激医美发展，美蜂互联网医 院成为业内新秀
          </div>
          <div class="item-desc">
            在如今的互联网社交当中，“颜值经济”这一词汇频繁出现在
            许多人的聊天记录中。“颜值经济”究竟是什么，又代表着怎 样的消费趋势…
          </div>
          <div class="item-arrow">
            <b-icon icon="arrow-right"></b-icon>
          </div>
        </div>
      </div>

      <div class="navpot">
        <div class="pots" :class="current == index?'active':''" v-for="(item, index) in pots" :key="index" @click="current = index"></div>
      </div>
    </div>
    <div class="news">
      <div class="title">来美蜂，你将收获极致的医美体验</div>
      <ul>
        <li>
          <div class="icon">01</div>
          <div class="right">
            <div style="font-size: 20px; margin-bottom: 10px; color: #333">
              什么是美蜂医疗
            </div>
            <p>
              美蜂医疗成立于2020年，总部位于中国杭州,
              由国资委与国有资产监管委员会联合控股。
              美蜂专注于打造医生与用户之间零距离沟通的一站式医美消费平台。
              集中整合优质医疗整形专家资源，建立信息全面的医生数据库，搭建全链条一站式管家服务体系。
            </p>
          </div>
        </li>
        <li>
          <div class="icon">02</div>
          <div class="right">
            <div style="font-size: 20px; margin-bottom: 10px; color: #333">
              怎么参加预约呢？
            </div>
            <p>
              可以在官网直击点击“添加客服”按钮，根据提示流程进行详细咨询，进一步预约。
            </p>
          </div>
        </li>
        <li>
          <div class="icon">03</div>
          <div class="right">
            <div style="font-size: 20px; margin-bottom: 10px; color: #333">
              如何快速找到适合自己的医生？
            </div>
            <p>
              美蜂医疗拥有全国最权威全面的医生资源库，在客户及线下管家的指引下，我们将为每一位用户匹配最适合自己的医生，一对一专项定制美容服务。
            </p>
          </div>
        </li>
        <li>
          <div class="icon">04</div>
          <div class="right">
            <div style="font-size: 20px; margin-bottom: 10px; color: #333">
              什么是阳光险服务？
            </div>
            <p>
              美蜂首创为每一位平台消费者以及整形医生提供免费的阳光保险服务，真正为消费者保驾护航，给予消费者最贴心、最放心的服务保障。
            </p>
          </div>
        </li>
        <li>
          <div class="icon">05</div>
          <div class="right">
            <div style="font-size: 20px; margin-bottom: 10px; color: #333">
              项目太多，不知道如何选择怎么办？
            </div>
            <p>
              您不用担心！我们为每一位意向客户配备了专业客服在线解答您的所有疑问，就算您是第一次做医美的小白，我们也绝不轻视。
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    to() {
      this.$router.push({
        path: "/news_info1"
      })
    }
  },
  data() {
    return {
      current: 0,
      pots: [1, 2, 3],
    };
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.banner {
  width: 1200px;
  .banner-img {
    width: 100%;
    height: auto;
  }
}
.news {
  width: 100%;
  background: url("../../static/news/background.png") no-repeat;
  background-size: contain;
  height: 881px;
  margin: 20px 0;
  padding: 20px 0;
  .title {
    font-size: 26px;
    text-align: center;
  }
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      list-style: none;
      width: 1200px;
      display: flex;
      margin: 25px 0;
      .icon {
        width: 50px;
        height: 50px;
        background: url("../../static/news/label.png") no-repeat;
        background-size: 50px;
        text-align: center;
        margin-right: 10px;
        line-height: 50px;
        font-size: 21px;
        color: #fff;
      }
      .right {
        width: 1140px;
      }
    }
  }
}

.course {
  // height: 520px;
  width: 1200px;
  margin: 30px 0;
  .tab-page {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .item {
      width: 360px;
    }
    .item:hover {
      cursor: pointer;
    }
    .item-num {
      font-size: 62px;
      font-weight: bold;
      color: #cecece;
    }
    .item-time {
      font-size: 14px;
      color: #999;
    }
    .item-title {
      margin-top: 20px;
      font-size: 20px;
      color: #333;
    }
    .item-desc {
      margin-top: 10px;
      color: #999;
    }
    .item-arrow {
      margin-top: 10px;
      color: #999;
    }
  }
  .navpot {
    display: flex;
    justify-content: center;
    height: 24px;
    align-items: center;
    width: 1200px;
    margin: 20px 0;
    .pots {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #ccc;
      margin: 0 20px;     
    }
    .pots:hover {
      cursor: pointer;
    }
    .active {
      background: #fd9852!important;
    }
  }
}
</style>
